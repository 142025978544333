/**
 * Chatbot Component
 *
 * Core Functionality:
 * 1. Manages conversation flow between user and bot
 * 2. Handles both structured (options) and free-form (text) inputs
 * 3. Supports various message types (text, carousel, grid, sections)
 * 4. Maintains conversation history in session storage
 * 5. Tracks analytics and user interactions
 *
 * Message Flow:
 * 1. User Input Phase:
 *    - User selects predefined option or types message
 *    - Input is validated if required (email, phone)
 *    - Message is added to conversation history
 *
 * 2. Bot Response Phase:
 *    - Shows typing indicator
 *    - Processes response based on user input
 *    - Can insert special messages at specific query counts
 *    - Displays follow-up questions if available
 *
 * Special Features:
 * - Message Insertion: Injects predetermined messages at specific interaction points
 * - Input Validation: Validates email/phone when required
 * - Analytics Tracking: Monitors message counts, timing, and user interactions
 * - Conversation Persistence: Saves chat history in session storage
 *
 * UI Components:
 * - Message Types: Text, Options, Carousel, Grid, Sections
 * - Typing Indicators: Shows when bot is "typing"
 * - Input Controls: Handles both option selection and text input
 * - Header Controls: Reset and close functionality
 */

import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  ConversationHeader,
  Avatar,
  // Button,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  createBotMessage,
  createOptionsFromFlow,
  getNextQuestion,
  getResponse,
} from '../utils/chatUtils';
import { formatResponseToMessages } from '../utils/messageFormatter';
import { ChatControls } from './ChatControls';
import { ChatOptions } from './MessageTypes/ChatOptions';
import ChatSwiperCarousel from './ChatSwiperCarousel';
import { ChatGrid } from './ChatGrid';
import { ChatSection } from './MessageTypes/ChatSection';
import {
  createNewConversation,
  getCurrentConversation,
  updateConversation,
  getConversationState,
  updateConversationState,
} from '../utils/conversationManager';
import {
  shouldInsertMessage,
  getInsertedMessages,
  validateMessage,
  INSERT_MESSAGE_TYPE,
} from '../utils/messageInserter';
import { ChatSectionWithCarousel } from './MessageTypes/ChatSectionWithCarousel';
import { ChatCallToAction } from './MessageTypes/ChatCallToAction';
import { ChatSectionWithSubSection } from './MessageTypes/ChatSectionWithSubSection';
import { ChatRedirectButton } from './MessageTypes/ChatRedirectButton';
import chatLogo from '../../../images/Chatbot/chatLogo.png';
import { ChatQuestionGroup } from './MessageTypes/ChatQuestionGroup';
import { ChatResponseGroup } from './MessageTypes/ChatResponseGroup';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import { CHATBOT_EVENTS } from '../constants/events';
import { GTM } from '@src/analytics/gtm';
import { processAIQuery, resetAIConversation } from '../utils/aiUtils';

export const Chatbot = ({ onClose }) => {
  // Core state management for chat functionality
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const [messages, setMessages] = useState([]); // Stores complete message history
  const [isTypingActive, setIsTypingActive] = useState(false); // Controls text input visibility
  const [isTyping, setIsTyping] = useState(false); // Controls typing animation
  const [conversationId, setConversationId] = useState(null); // Unique chat session ID
  const [userQueryCount, setUserQueryCount] = useState(0); // Tracks interaction count
  const [isProcessing, setIsProcessing] = useState(false); // Prevents concurrent requests
  const [validationError, setValidationError] = useState(null); // Input validation errors
  const [currentValidation, setCurrentValidation] = useState(null); // Active validation rules
  const [allowManualTyping, setAllowManualTyping] = useState(false); // Free text input control
  const [pendingAIResponse, setPendingAIResponse] = useState(null);

  // Refs for message handling and UI updates
  const handleSendRef = React.useRef(null); // Current message handler
  const latestUserMessageRef = useRef(null); // Latest message for scrolling

  // Analytics tracking state
  const [messageStats, setMessageStats] = useState({
    userMessageCount: 0, // Total user messages sent
    botMessageCount: 0, // Total bot responses
    lastUserMessage: '', // Most recent user message
    lastBotMessage: '', // Most recent bot response
    lastUserMessageTime: null, // Timestamp of last user message
    lastBotMessageTime: null, // Timestamp of last bot response
  });

  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];

      if (lastMessage.sender === 'user') {
        setMessageStats((prev) => ({
          ...prev,
          userMessageCount: prev.userMessageCount + 1,
          lastUserMessage: lastMessage.message,
          lastUserMessageTime: lastMessage.sentTime,
        }));
      } else {
        // Find the last actual response from bot (excluding follow-up questions)
        let lastBotResponse = null;
        for (let i = messages.length - 1; i >= 0; i--) {
          const msg = messages[i];
          const nextMsg = messages[i + 1];

          // Skip if this is a question followed by options (follow-up question)
          if (msg.type === 'text' && nextMsg?.type === 'options') {
            continue;
          }

          // Found the last actual bot response
          if (msg.sender === 'bot' && msg.type !== 'options') {
            lastBotResponse = msg;
            break;
          }
        }

        if (lastBotResponse) {
          setMessageStats((prev) => ({
            ...prev,
            botMessageCount: prev.botMessageCount + 1,
            lastBotMessage: lastBotResponse.message,
            lastBotMessageTime: lastBotResponse.sentTime,
          }));
        }
      }
    }
  }, [messages]);

  /**
   * Handles chat closure and triggers analytics
   */
  const handleChatClose = () => {
    GTM.track(CHATBOT_EVENTS.EXTERNAL_PLATFORM_EVENT, {
      type: CHATBOT_EVENTS.CHAT_CLOSED.type,
      location: CHATBOT_EVENTS.CHAT_CLOSED.locations.CHAT_WINDOW_CLOSED,
      chatbotMessage: messageStats.lastBotMessage,
      chatbotMessageOrder: messageStats.botMessageCount,
      chatbotMessageTime: messageStats.lastBotMessageTime,
      userMessage: messageStats.lastUserMessage,
      userMessageOrder: messageStats.userMessageCount,
      userMessageSentTime: messageStats.lastUserMessageTime,
    });
    onClose?.();
  };

  useEffect(() => {
    const handleWindowClose = (e) => {
      GTM.track(CHATBOT_EVENTS.EXTERNAL_PLATFORM_EVENT, {
        type: CHATBOT_EVENTS.CHAT_CLOSED.type,
        location: CHATBOT_EVENTS.CHAT_CLOSED.locations.BROWSER_CHAT_CLOSED,
        chatbotMessage: messageStats.lastBotMessage,
        chatbotMessageOrder: messageStats.botMessageCount,
        chatbotMessageTime: messageStats.lastBotMessageTime,
        userMessage: messageStats.lastUserMessage,
        userMessageOrder: messageStats.userMessageCount,
        userMessageSentTime: messageStats.lastUserMessageTime,
      });
    };

    window.addEventListener('beforeunload', handleWindowClose);
    return () => window.removeEventListener('beforeunload', handleWindowClose);
  }, [messageStats]);

  const handleResetChat = () => {
    GTM.track(CHATBOT_EVENTS.EXTERNAL_PLATFORM_EVENT, {
      type: CHATBOT_EVENTS.CHAT_CLICK.type,
      location: CHATBOT_EVENTS.CHAT_CLICK.locations.RESET_CHAT,
      chatbotMessageOrder: messageStats.botMessageCount,
      chatbotMessageTime: messageStats.lastBotMessageTime,
    });
    const newConversation = createNewConversation();
    setConversationId(newConversation.id);
    setMessages([]);
    setIsTypingActive(false);
    setUserQueryCount(0);
    resetAIConversation();
    initializeChat();
  };

  /**
   * Initializes chat with first message or restores previous conversation
   */
  const initializeChat = () => {
    const existingConversation = getCurrentConversation();

    if (existingConversation && existingConversation.messages.length > 0) {
      setConversationId(existingConversation.id);
      setMessages(existingConversation.messages);
      setUserQueryCount(existingConversation.userQueryCount || 0);

      // Restore persisted states
      const {
        isTypingActive: savedTypingActive,
        currentValidation: savedValidation,
        validationError: savedError,
        currentResponse: savedResponse,
      } = getConversationState();

      setIsTypingActive(savedTypingActive);
      setCurrentValidation(savedValidation);
      setValidationError(savedError);

      // If we have a saved response and we're in validation, set up for continuing the flow
      if (savedResponse && savedValidation) {
        handleSendRef.current = (message) => {
          if (!message.trim()) return;

          // Clean the input based on validation type
          const cleanedMessage =
            savedValidation.type === 'phone'
              ? message.replace(/[- ]/g, '')
              : message;

          // Show user's message
          const userTextMessage = {
            message: message,
            sentTime: new Date().toISOString(),
            sender: 'user',
            direction: 'outgoing',
            position: 'single',
            type: 'text',
          };

          // Add user message
          setMessages((prevMessages) => {
            const newMessages = [...prevMessages, userTextMessage];
            updateConversation(newMessages, true, {
              currentResponse: savedResponse,
              isTypingActive: true,
              currentValidation: savedValidation,
              validationError: null,
            });
            return newMessages;
          });

          // Start typing indicator
          setIsTyping(true);

          // Validate input using saved validation config
          const validationResult = validateMessage(
            cleanedMessage,
            savedValidation.validation,
            savedValidation,
            dispatch,
            state,
          );

          setTimeout(() => {
            if (!validationResult.isValid) {
              // Show error messages
              const botMessages = [
                {
                  message: validationResult.errorMessage,
                  sentTime: new Date().toISOString(),
                  sender: 'bot',
                  direction: 'incoming',
                  position: 'single',
                  type: 'text',
                },
                {
                  message:
                    savedValidation?.validation?.type === 'email'
                      ? 'Please enter your email address again'
                      : 'Please enter your phone number again',
                  sentTime: new Date().toISOString(),
                  sender: 'bot',
                  direction: 'incoming',
                  position: 'single',
                  type: 'text',
                },
              ];

              setMessages((prevMessages) => {
                const newMessages = [...prevMessages, ...botMessages];
                updateConversation(newMessages, false, {
                  currentResponse: savedResponse,
                  isTypingActive: true,
                  currentValidation: savedValidation,
                  validationError: validationResult.errorMessage,
                });
                return newMessages;
              });

              // Update error states
              setValidationError(validationResult.errorMessage);
              setIsTypingActive(true);
              setIsTyping(false);
            } else {
              // Valid input - proceed with flow
              setValidationError(null);
              setCurrentValidation(null);
              setIsTypingActive(false);
              handleRestOfFlow(savedResponse);
            }
          }, 2000);
        };
      }
    } else {
      const newConversation = createNewConversation();
      setConversationId(newConversation.id);
      setUserQueryCount(0);

      const initialFlow = getNextQuestion('q1');
      if (initialFlow) {
        const options = createOptionsFromFlow(initialFlow);
        const initialMessages = [
          createBotMessage(initialFlow.question, 'text'),
          createBotMessage('How can I help you?', 'options', options),
        ];
        setMessages(initialMessages);
        updateConversation(initialMessages);
      }
    }
  };

  useEffect(() => {
    initializeChat();
  }, []);

  /**
   * Resets chat to initial state
   */
  const handleReset = () => {
    const newConversation = createNewConversation();
    setConversationId(newConversation.id);
    setMessages([]);
    setIsTypingActive(false);
    setUserQueryCount(0);
    initializeChat();
  };

  /**
   * Handles the rest of the conversation flow after validation
   * @param {Object} responseToUse - The response object to continue the flow with
   */
  const handleRestOfFlow = (responseToUse) => {
    setIsTyping(true);

    // Get the stored response from conversation state if not provided
    if (!responseToUse) {
      const state = getConversationState();
      responseToUse = state.currentResponse;
    }

    setTimeout(() => {
      setMessages((prevMsgs) => {
        let newMessages = [...prevMsgs];

        // Add the original responses first if they exist
        if (responseToUse?.selectionResponseId) {
          const originalResponses = formatResponseToMessages(
            responseToUse.selectionResponseId,
          );
          newMessages = [...newMessages, ...originalResponses];
        }

        // Add pending AI response if exists
        if (pendingAIResponse) {
          newMessages = [...newMessages, pendingAIResponse];
          setPendingAIResponse(null);
        }

        // Add follow-up question if exists
        if (responseToUse?.followupQuestionId) {
          const nextFlow = getNextQuestion(responseToUse.followupQuestionId);
          if (nextFlow) {
            const options = createOptionsFromFlow(nextFlow);
            newMessages = [
              ...newMessages,
              createBotMessage(nextFlow.question, 'text'),
              createBotMessage('Please select an option:', 'options', options),
            ];
          }
        }

        updateConversation(newMessages, false, {
          currentResponse: null, // Clear the stored response
          currentValidation: null,
          validationError: null,
        });
        return newMessages;
      });

      setIsTyping(false);
      setIsProcessing(false);
      setCurrentValidation(null);
      setValidationError(null);
    }, 2000);
  };

  /**
   * Handles user option selection and triggers bot response flow
   * @param {string} value - Selected option value
   */
  const handleOptionSelect = async (value) => {
    if (isProcessing || isTyping) return;

    const response = getResponse(value);
    if (!response) return;

    setIsProcessing(true);
    setIsTyping(true);

    const newQueryCount = userQueryCount + 1;
    setUserQueryCount(newQueryCount);

    // Create user message
    const userMessage = {
      message: response.text,
      sentTime: new Date().toISOString(),
      sender: 'user',
      direction: 'outgoing',
      position: 'single',
      type: 'text',
    };

    // Update messages and persist response
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages, userMessage];
      updateConversation(newMessages, true, {
        currentResponse: response,
        currentValidation: null,
        validationError: null,
      });
      return newMessages;
    });

    // Handle bot response flow
    if (response.selectionResponseId) {
      const botResponses = formatResponseToMessages(
        response.selectionResponseId,
      );

      setTimeout(() => {
        setMessages((prevMessages) => {
          let newMessages = [...prevMessages];

          if (shouldInsertMessage(newQueryCount)) {
            const { messages: insertedMessages, config } =
              getInsertedMessages(newQueryCount);
            newMessages = [...newMessages, ...insertedMessages];

            if (config.type === INSERT_MESSAGE_TYPE.VALIDATION_REQUIRED) {
              setCurrentValidation(config);

              // Persist validation state and inserted messages
              updateConversationState({
                messages: newMessages,
                currentValidation: config,
                currentResponse: response,
              });

              return newMessages;
            }

            // Normal flow without validation
            newMessages = [...newMessages, ...botResponses];

            // Add follow-up question if exists
            if (response.followupQuestionId) {
              const nextFlow = getNextQuestion(response.followupQuestionId);
              if (nextFlow) {
                const options = createOptionsFromFlow(nextFlow);
                newMessages = [
                  ...newMessages,
                  createBotMessage(nextFlow.question, 'text'),
                  createBotMessage(
                    'Please select an option:',
                    'options',
                    options,
                  ),
                ];
              }
            }

            updateConversation(newMessages);
            return newMessages;
          }

          // Normal flow without message insertion
          newMessages = [...newMessages, ...botResponses];

          // Add follow-up question if exists
          if (response.followupQuestionId) {
            const nextFlow = getNextQuestion(response.followupQuestionId);
            if (nextFlow) {
              const options = createOptionsFromFlow(nextFlow);
              newMessages = [
                ...newMessages,
                createBotMessage(nextFlow.question, 'text'),
                createBotMessage(
                  'Please select an option:',
                  'options',
                  options,
                ),
              ];
            }
          }

          updateConversation(newMessages);
          return newMessages;
        });

        setIsTyping(false);
        setIsProcessing(false);
      }, 2000);
    }
  };

  /**
   * Handles user message input for both AI and validation flows
   * @param {string} message - User's message
   */
  const handleMessageSend = async (message) => {
    if (!message.trim()) return;

    // Create user message
    const userTextMessage = {
      message: message,
      sentTime: new Date().toISOString(),
      sender: 'user',
      direction: 'outgoing',
      position: 'single',
      type: 'text',
    };

    // Add user message to chat
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages, userTextMessage];
      updateConversation(newMessages, true);
      return newMessages;
    });

    setIsTyping(true);

    // Check if we're in validation mode
    if (currentValidation) {
      // Clean the input based on validation type
      const cleanedMessage =
        currentValidation.type === 'phone'
          ? message.replace(/[- ]/g, '')
          : message;

      // Validate input

      const validationResult = validateMessage(
        cleanedMessage,
        currentValidation.validation,
        currentValidation,
        dispatch,
        state,
      );

      setTimeout(() => {
        if (!validationResult.isValid) {
          // Show error messages
          const botMessages = [
            {
              message: validationResult.errorMessage,
              sentTime: new Date().toISOString(),
              sender: 'bot',
              direction: 'incoming',
              position: 'single',
              type: 'text',
            },
            {
              message:
                currentValidation?.validation?.type === 'email'
                  ? 'Please enter your email address again'
                  : 'Please enter your phone number again',
              sentTime: new Date().toISOString(),
              sender: 'bot',
              direction: 'incoming',
              position: 'single',
              type: 'text',
            },
          ];

          setMessages((prevMessages) => {
            const newMessages = [...prevMessages, ...botMessages];
            updateConversation(newMessages);
            return newMessages;
          });

          setValidationError(validationResult.errorMessage);
          setIsTyping(false);
        } else {
          // Valid input - proceed with flow
          setValidationError(null);
          setCurrentValidation(null);
          handleRestOfFlow(null);
        }
      }, 2000);
    } else {
      // AI Flow
      try {
        const aiResponse = await processAIQuery(message);
        const newQueryCount = userQueryCount + 1;
        setUserQueryCount(newQueryCount);

        // Check if we need to insert a message
        if (shouldInsertMessage(newQueryCount)) {
          setPendingAIResponse(aiResponse);
          const { messages: insertedMessages, config } =
            getInsertedMessages(newQueryCount);

          if (config.type === INSERT_MESSAGE_TYPE.VALIDATION_REQUIRED) {
            setCurrentValidation(config);
            setMessages((prevMessages) => {
              const newMessages = [...prevMessages, ...insertedMessages];
              updateConversation(newMessages, false, {
                currentValidation: config.validation,
                pendingAIResponse: aiResponse,
              });
              return newMessages;
            });
          }
        } else {
          // Show AI response immediately
          setMessages((prevMessages) => {
            const newMessages = [...prevMessages, aiResponse];
            updateConversation(newMessages);
            return newMessages;
          });
        }
      } catch (error) {
        setMessages((prevMessages) => {
          const newMessages = [
            ...prevMessages,
            createBotMessage(
              "I'm having trouble understanding your request right now. Would you like to connect with our program advisor who can help you better?",
            ),
            {
              message: 'Book a free trial',
              sentTime: new Date().toISOString(),
              sender: 'bot',
              direction: 'incoming',
              position: 'last',
              type: 'callToAction',
              payload: {
                text: 'Connect to a Program Advisor',
                link: 'https://crio.do/free-trial',
              },
            },
          ];
          updateConversation(newMessages);
          return newMessages;
        });
      }
      setIsTyping(false);
    }
  };

  /**
   * Enables manual typing mode for free-form input
   */
  const handleManualTypeClick = () => {
    setIsTypingActive(true);
    setAllowManualTyping(true);
    handleSendRef.current = (message) => {
      if (!message.trim()) return;

      const userTextMessage = {
        message: message,
        sentTime: new Date().toISOString(),
        sender: 'user',
        direction: 'outgoing',
        position: 'single',
        type: 'text',
      };

      setMessages((prevMessages) => {
        const newMessages = [...prevMessages, userTextMessage];
        updateConversation(newMessages, true);
        return newMessages;
      });
    };
  };

  useEffect(() => {
    if (latestUserMessageRef.current) {
      // Find the scrollable container
      const scrollContainer = document.querySelector(
        '.cs-message-list__scroll-wrapper',
      );
      if (scrollContainer) {
        // Get the user message position relative to the scroll container
        const userMessagePosition = latestUserMessageRef.current.offsetTop;

        // Scroll the container
        scrollContainer.scrollTop = userMessagePosition - 100; // 100px offset from top
      }
    }
  }, [messages]);

  /**
   * Renders individual message based on type
   * @param {Object} message - Message object to render
   * @param {number} index - Message index in list
   * @param {Array} messages - Complete message list
   */
  const renderMessage = (message, index, messages) => {
    switch (message.type) {
      case 'text':
        return (
          <Message
            key={index}
            model={{
              message: message.message,
              sentTime: message.sentTime,
              sender: message.sender,
              direction: message.direction,
              position: message.position,
            }}
            className="text-[#FFFFFF]"
          >
            {message.type !== 'text' && message.payload && (
              <Message.CustomContent>
                {message.type === 'options' && (
                  <ChatOptions
                    items={message.payload}
                    onSelect={handleOptionSelect}
                    disabled={
                      !message.type === 'options' || isProcessing || isTyping
                    }
                  />
                )}
                {message.type === 'carousel' && (
                  <ChatSwiperCarousel items={message.payload} />
                )}
                {message.type === 'grid' && (
                  <ChatGrid items={message.payload} />
                )}
                {message.type === 'section' && (
                  <ChatSection items={message.payload} />
                )}
                {message.type === 'section_with_carousel' && (
                  <ChatSectionWithCarousel items={message.payload} />
                )}
                {message.type === 'callToAction' && (
                  <ChatCallToAction
                    text={message.payload.text}
                    link={message.payload.link}
                    programInterested={message.payload?.programInterested}
                  />
                )}
                {message.type === 'section_with_sub_section' && (
                  <ChatSectionWithSubSection items={message.payload} />
                )}
                {message.type === 'redirectButton' && (
                  <ChatRedirectButton
                    text={message.payload.text}
                    link={message.payload.link}
                  />
                )}
              </Message.CustomContent>
            )}
          </Message>
        );
      case 'options':
        return (
          <Message
            key={index}
            model={{
              direction: message.direction,
              position: message.position,
            }}
          >
            <ChatQuestionGroup
              question={message}
              options={messages[index + 1]}
              onSelect={handleOptionSelect}
              isProcessing={isProcessing}
              isTyping={isTyping}
            />
          </Message>
        );
      case 'carousel':
        return (
          <Message
            key={index}
            model={{
              direction: message.direction,
              position: message.position,
            }}
          >
            <ChatSwiperCarousel items={message.payload} />
          </Message>
        );
      case 'section_title':
        return (
          <Message
            key={index}
            model={{
              direction: message.direction,
              position: message.position,
            }}
          >
            <div className="text-lg font-semibold text-black">
              {message.message}
            </div>
          </Message>
        );
      case 'section_content':
        return (
          <Message
            key={index}
            model={{
              direction: message.direction,
              position: message.position,
            }}
          >
            <div className="text-sm text-black/80">{message.message}</div>
          </Message>
        );
      default:
        return null;
    }
  };

  /**
   * Groups and renders messages with proper formatting
   * @param {Array} messages - List of messages to render
   */
  const renderMessages = (messages) => {
    const messageElements = [];
    let currentResponseGroup = [];

    const addResponseGroup = () => {
      if (currentResponseGroup.length > 0) {
        messageElements.push(
          <ChatResponseGroup
            key={`response-group-${messageElements.length}`}
            messages={currentResponseGroup}
          />,
        );
        currentResponseGroup = [];
      }
    };

    for (let i = 0; i < messages.length; i++) {
      const msg = messages[i];
      const nextMsg = messages[i + 1];

      if (msg.type === 'text' && nextMsg?.type === 'options') {
        addResponseGroup();
        messageElements.push(
          <ChatQuestionGroup
            key={`group-${i}`}
            question={msg}
            options={nextMsg}
            onSelect={handleOptionSelect}
            isProcessing={isProcessing}
            isTyping={isTyping}
          />,
        );
        i++;
      } else if (msg.direction === 'outgoing') {
        addResponseGroup();
        // Wrap user message in a div with ref
        messageElements.push(
          <div key={`user-message-${i}`} ref={latestUserMessageRef}>
            {renderMessage(msg, i, messages)}
          </div>,
        );
      } else {
        currentResponseGroup.push(msg);
      }
    }

    addResponseGroup();
    return messageElements;
  };

  return (
    <div className="fixed bottom-0 right-0 h-full w-full overflow-hidden bg-gray-900 shadow-xl md:bottom-12 md:right-12 md:h-[70vh] md:w-[500px] md:rounded-xl">
      <MainContainer className="dark-theme">
        <ChatContainer>
          <ConversationHeader className="dark-header">
            <Avatar src={chatLogo} name="Kiran" />
            <ConversationHeader.Content
              userName="Hi! How may I help you?"
              className="dark-header text-sm text-white md:text-base"
            />
            <ConversationHeader.Actions>
              <ChatControls
                onClose={() => {
                  handleChatClose();
                  onClose();
                }}
                onReset={() => {
                  handleResetChat();
                  handleReset();
                }}
              />
            </ConversationHeader.Actions>
          </ConversationHeader>
          <MessageList
            className="dark-message-list"
            typingIndicator={
              isTyping ? (
                <TypingIndicator
                  content="Kiran is typing"
                  className="text-black"
                />
              ) : null
            }
          >
            {renderMessages(messages)}
          </MessageList>
          <MessageInput
            placeholder={
              currentValidation?.type === 'phone'
                ? 'Enter your phone number...'
                : currentValidation?.type === 'email'
                ? 'Enter your email address...'
                : 'Type your message here...'
            }
            onSend={handleMessageSend}
            disabled={isProcessing || isTyping}
            className="dark-message-input text-white"
            attachButton={false}
            sendButton={true}
          />
          {validationError && (
            <div className="px-4 py-1 text-sm text-red-500">
              {validationError}
            </div>
          )}
        </ChatContainer>
      </MainContainer>

      <style jsx global>{`
        /* Reset and base styles */
        .cs-main-container,
        .cs-chat-container,
        .cs-message-list,
        .cs-message-list__scroll-wrapper {
          background-color: #ffffff !important;
        }

        /* Header styles */
        .cs-conversation-header,
        .dark-header {
          background-color: #013437 !important;
          border-bottom: none !important;
        }

        .cs-conversation-header *,
        .cs-conversation-header__content,
        .cs-conversation-header__user-name {
          color: #ffffff !important;
          background-color: #013437 !important;
          border: none !important;
        }

        /* Remove any underlines */
        .cs-conversation-header__content::after,
        .cs-conversation-header__content::before {
          display: none !important;
        }

        /* Base message styles */
        .cs-message__content {
          padding: 12px 16px !important;
        }

        /* Outgoing messages (user) */
        .cs-message--outgoing {
          display: flex !important;
          justify-content: flex-end !important;
          padding-right: 16px !important;
          margin: 8px 0 !important;
          width: 100% !important;
        }

        .cs-message--outgoing .cs-message__content-wrapper {
          margin: 2px 0px !important;
          background: transparent !important;
          padding: 0 !important;
          border-radius: 0 !important;
          width: fit-content !important;
          display: flex !important;
          flex-direction: row-reverse !important;
        }

        .cs-message--outgoing .cs-message__content {
          background-color: #2df8c5 !important;
          color: #000000 !important;
          border-radius: 16px !important;
          margin-left: auto !important;
          margin: 8px 0px !important;
          width: auto !important;
        }

        /* Remove any container constraints */
        .cs-message-list__scroll-wrapper {
          width: 100% !important;
        }

        .cs-message__content-wrapper {
          width: 100% !important;
        }

        /* Message container */
        .cs-message {
          width: 100% !important;
          max-width: none !important;
          padding: 0 !important;
        }

        /* Incoming messages (bot) */
        .cs-message--incoming .cs-message__content {
          border-radius: 16px !important;
          color: #ffffff !important;
        }

        /* Message spacing */
        .cs-message {
          margin: 4px 0 !important;
        }

        /* Input area styles */

        .cs-message-input__content-editor-wrapper,
        .cs-message-input__content-editor {
          margin: 0px 0px 0px 10px !important;
          border-radius: 10px !important;
          background-color: #efefef !important;
          color: #000000 !important;
        }

        .cs-message-input__content-editor[data-placeholder]:empty:before {
          color: #000000 !important;
          opacity: 0.7;
        }

        .cs-message-input__content-editor-container {
          background-color: #efefef !important;
        }

        .cs-message-input__tools > *:first-child {
          color: black !important;
        }

        .cs-message--incoming {
          margin: 0 !important;
        }

        /* Button styles */
        .cs-button {
          color: #ffffff !important;
        }

        .cs-button--send {
          color: #3b82f6 !important;
        }

        /* Typing indicator */
        .cs-typing-indicator {
          background-color: transparent !important;
          color: black !important;
        }

        .cs-typing-indicator__dot {
          background-color: black !important;
        }

        .cs-typing-indicator__text {
          color: black !important;
        }

        /* Options and interactive elements */
        .cs-message-list__typing-indicator {
          background-color: #333333 !important;
          color: #ffffff !important;
        }

        /* Incoming messages (bot) - within wrapper */
        .cs-message--incoming .cs-message__content {
          background-color: #b9cfca !important;
          border-radius: 8px !important;
          color: #000000 !important;
        }

        /* Remove default message margins for bot messages (handled by wrapper) */
        .cs-message--incoming {
          margin: 0 !important;
        }

        /* Keep margins for user messages */
        .cs-message--outgoing {
          margin: 8px 0 !important;
        }

        /* Add or update the MessageList styles */
        .cs-message-list {
          padding-bottom: 20px !important;
        }
      `}</style>
    </div>
  );
};
