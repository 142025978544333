/**
 * Handles AI-related functionality for the chatbot
 */

import { createBotMessage } from './chatUtils';

const API_KEY = process.env.OPEN_AI_SERVICE_X_API_KEY;

const ASSISTANT_ID = 'asst_9sFQflJgfvlEoT0iRR734gdL';
const BASE_URL =
  'https://9ucjoxqsdi.execute-api.ap-south-1.amazonaws.com/dev/v1/assistant/thread';
const SIGNATURE_URL =
  'https://9ucjoxqsdi.execute-api.ap-south-1.amazonaws.com/dev/external-chatbot-util-service?path=generate-signature';

// Store thread ID in session storage
const THREAD_KEY = 'chatbot_thread_id';

/**
 * The AI responses can contain various types of line breaks and citation markers.
 * This function normalizes content to have single line breaks between elements.
 *
 * @param {string} message - The message to sanitize
 * @returns {string} Sanitized message with normalized line breaks
 */
const sanitizeMessage = (message) => {
  return (
    message
      // First remove citations
      .replace(/【\d+:\d+†source】/g, '')
      // Convert br tags to newlines
      .replace(/<br\s*\/?>/gi, '\n')
      .replace(/<br>/gi, '\n')
      // Add line breaks between elements
      .replace(/(<\/p>)(<[pb]|ul)/gi, '$1\n$2') // Add newline after </p>
      .replace(/(<\/ul>)(<[pb]|ul)/gi, '$1\n$2') // Add newline after </ul>
      .replace(/(<\/b>)(<[pb]|ul)/gi, '$1\n$2') // Add newline after </b>
      .replace(/(\d+\.\s*)(<[pb]|ul)/g, '$1\n$2') // Add newline after numbered sections
      // Ensure line breaks around list items and sections
      .replace(/(<\/li>)(<li|<\/ul|<b)/g, '$1\n$2') // Add newline after list items
      .replace(/(<b>\d+\.)/g, '\n$1') // Add newline before numbered sections
      .replace(/(<\/b>)(\s*\n*)/g, '$1\n') // Add newline after section headers
      // Clean up but preserve single line breaks
      .replace(/\n\s*\n+/g, '\n') // Replace multiple newlines with single newline
      .replace(/\s+/g, ' ') // Normalize spaces
      .replace(/>\s+</g, '>\n<') // Ensure newline between tags
      .trim()
  );
};

const getThreadId = () => {
  return sessionStorage.getItem(THREAD_KEY);
};

const setThreadId = (threadId) => {
  sessionStorage.setItem(THREAD_KEY, threadId);
};

const clearThreadId = () => {
  sessionStorage.removeItem(THREAD_KEY);
};

/**
 * Gets a signature for API authentication
 * @returns {Promise<string>} Signature for API calls
 */
const getSignature = async () => {
  try {
    const response = await fetch(SIGNATURE_URL);
    if (!response.ok) {
      throw new Error('Failed to get signature');
    }
    const data = await response.json();
    return data.signature;
  } catch (error) {
    console.error('Error getting signature:', error);
    throw error;
  }
};

/**
 * Makes the initial API call to create a thread and get first response
 * @param {string} message - User's message
 * @returns {Promise<Object>} Response from the assistant
 */
const makeInitialCall = async (message) => {
  try {
    const signature = await getSignature();

    const response = await fetch(`${BASE_URL}/run`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY,
        'x-signature': signature,
      },
      body: JSON.stringify({
        assistant_id: ASSISTANT_ID,
        model: 'gpt-4o-mini',
        messages: [
          {
            role: 'user',
            content: message,
          },
        ],
        response_format: {
          type: 'text',
        },
        project_name: 'EXTERNAL_CHATBOT',
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to get initial response');
    }

    const data = await response.json();

    // Store thread ID for future use
    setThreadId(data.open_ai_run.thread_id);

    // Get the last message from assistant and sanitize it
    const lastMessage = data.messages[data.messages.length - 1];
    return sanitizeMessage(lastMessage.content[0].text.value);
  } catch (error) {
    console.error('Error in initial API call:', error);
    throw error;
  }
};

/**
 * Makes subsequent API calls using the stored thread ID
 * @param {string} message - User's message
 * @param {string} threadId - Thread ID from previous conversation
 * @returns {Promise<Object>} Response from the assistant
 */
const makeContinuationCall = async (message, threadId) => {
  try {
    const signature = await getSignature();

    const response = await fetch(`${BASE_URL}/${threadId}/run`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY,
        'x-signature': signature,
      },
      body: JSON.stringify({
        assistant_id: ASSISTANT_ID,
        model: 'gpt-4o-mini',
        messages: [
          {
            role: 'user',
            content: message,
          },
        ],
        response_format: {
          type: 'text',
        },
        project_name: 'EXTERNAL_CHATBOT',
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to get continuation response');
    }

    const data = await response.json();

    // Get the last message from assistant and sanitize it
    const lastMessage = data.messages[data.messages.length - 1];
    return sanitizeMessage(lastMessage.content[0].text.value);
  } catch (error) {
    console.error('Error in continuation API call:', error);
    throw error;
  }
};

/**
 * Process a user query through AI
 * @param {string} query - User's message
 * @returns {Promise<Object>} AI response message object
 */
export const processAIQuery = async (query) => {
  try {
    const threadId = getThreadId();
    let response;

    if (!threadId) {
      // First time query - create new thread
      response = await makeInitialCall(query);
    } else {
      // Continuation of existing conversation
      response = await makeContinuationCall(query, threadId);
    }

    return createBotMessage(response);
  } catch (error) {
    console.error('Error processing AI query:', error);
    clearThreadId(); // Clear thread ID on error to start fresh next time
    throw error;
  }
};

/**
 * Reset the AI conversation state
 */
export const resetAIConversation = () => {
  clearThreadId();
};

/**
 * Checks if the current flow should use AI
 * @param {Object} currentValidation - Current validation state
 * @param {boolean} isProcessing - Whether a message is being processed
 * @returns {boolean} Whether to use AI flow
 */
export const shouldUseAIFlow = (currentValidation, isProcessing) => {
  return !currentValidation && !isProcessing;
};
