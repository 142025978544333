import React, { useEffect } from 'react';
import SEO from '@components/Common/SEO/index';
import Navigation from '@components/Navigation/index';
import HeroSection from '@components/AboutUsPageV2/HeroSection/index';
import aboutUs from '@src/PageData/aboutUs.json';
import NoteFromLeadership from '@components/AboutUsPageV2/NoteFromLeadership/index';
import CrioJourney from '@components/AboutUsPageV2/CrioJourney/index';
import EmployeeTestimonial from '@components/AboutUsPageV2/EmployeeTestimonials/index';
import WorkWithUsSection from '@components/AboutUsPageV2/WorkWithUsSection/index';
import FooterV2 from '@components/FooterV2/index';
import { useLocation } from '@reach/router';

export default function AboutUsPage() {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the #workwithus section if the URL has #workwithus
    if (location?.hash && location.hash === '#workwithus') {
      document
        .getElementById('workwithus')
        .scrollIntoView({ behavior: 'smooth' });
    }

    // Scroll to the #criojourney section if the URL has #criojourney
    if (location?.hash && location.hash === '#criojourney') {
      document
        .getElementById('criojourney')
        .scrollIntoView({ behavior: 'smooth' });
    }
  }, [location?.hash]);

  return (
    <>
      {/* SEO */}
      <SEO
        title="About Us"
        description="Get real software development experience. Work on challenging projects. Learn in-demand tech skills to excel in your career"
      ></SEO>

      {/* Section 0: Navbar with Top Strip */}
      <Navigation />

      {/* Section 1: Hero Section */}
      <HeroSection data={aboutUs.heroSectionDetails} />

      {/* Section 2: Note from our leadership team */}
      <NoteFromLeadership data={aboutUs?.noteFromLeadershipDetails} />

      {/* Section 3: Crio Journey */}
      <CrioJourney data={aboutUs?.crioJourneyDetails} />

      {/* Section 4: Employee Testimonial */}
      <EmployeeTestimonial data={aboutUs?.employeeTestimonials} />

      {/* Section 5: Job Application */}
      <WorkWithUsSection data={aboutUs?.jobApplicationDeatils} />

      {/* Section 6: Footer */}
      <FooterV2 />
    </>
  );
}
